/*
 * Ripped from the "TailwindCSS" example on https://react-hot-toast.com/
 * Unclear where these styles were coming from since they're not actually in the
 * github repo, but they were on the site and they work just the same like this.
 */

@keyframes enter {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes leave {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(0.9);
    opacity: 0;
  }
}

.animate-enter {
  animation: enter 0.2s ease-out;
}

.animate-leave {
  animation: leave 0.15s ease-in forwards;
}
