[data-app-root],
[data-headlessui-portal] {
  --color-brand-hsl-h: 220;
  --color-brand-hsl-s: 100%;
  --color-brand-hsl-l: 57%;

  /*
   * Colors: Base Palette
   */

  --color-neutral-hsl-h: 220;
  --color-neutral-hsl-s: 12%;
  --color-neutral-hsl-l: 60%;
  --color-info-hsl-h: 204;
  --color-info-hsl-s: 100%;
  --color-info-hsl-l: 50%;
  --color-positive-hsl-h: 142;
  --color-positive-hsl-s: 71%;
  --color-positive-hsl-l: 50%;
  --color-negative-hsl-h: 346;
  --color-negative-hsl-s: 100%;
  --color-negative-hsl-l: 50%;
  --color-warning-hsl-h: 42;
  --color-warning-hsl-s: 100%;
  --color-warning-hsl-l: 50%;

  /*
   * Colors: Text
   */

  --color-txt-heading: theme("colors.private.neutral.950");
  --color-txt-body: theme("colors.private.neutral.800");
  --color-txt-label: theme("colors.private.neutral.800");
  --color-txt-subtext: theme("colors.private.neutral.500");
  --color-txt-inverse: theme("colors.private.neutral.0");
  --color-txt-brand: theme("colors.private.brand.500");
  --color-txt-info: theme("colors.private.info.500");
  --color-txt-positive: theme("colors.private.positive.600");
  --color-txt-negative: theme("colors.private.negative.500");
  --color-txt-warning: theme("colors.private.warning.500");

  /*
   * Colors: Border
   */

  --color-border-container: theme("colors.private.neutral.300");
  --color-border-divider: theme("colors.private.neutral.200");
  --color-border-brand: theme("colors.private.brand.500");
  --color-border-info: theme("colors.private.info.500");
  --color-border-positive: theme("colors.private.positive.600");
  --color-border-negative: theme("colors.private.negative.500");
  --color-border-warning: theme("colors.private.warning.500");

  /*
   * Colors: Background
   */

  --color-bg-main: theme("colors.private.neutral.100");
  --color-bg-container-default: theme("colors.private.neutral.0");
  --color-bg-container-accent: theme("colors.private.brand.500");
  --color-bg-highlight: theme("colors.private.brand.50");
  --color-bg-neutral: theme("colors.private.neutral.300");
  --color-bg-neutral-light: theme("colors.private.neutral.200");
  --color-bg-neutral-dark: theme("colors.private.neutral.800");
  --color-bg-info: theme("colors.private.info.500");
  --color-bg-info-light: theme("colors.private.info.50");
  --color-bg-positive: theme("colors.private.positive.600");
  --color-bg-positive-light: theme("colors.private.positive.50");
  --color-bg-negative: theme("colors.private.negative.500");
  --color-bg-negative-light: theme("colors.private.negative.50");
  --color-bg-warning: theme("colors.private.warning.500");
  --color-bg-warning-light: theme("colors.private.warning.50");

  /*
   * Colors: Icon
   */

  --color-icon-default: theme("colors.private.neutral.700");
  --color-icon-inverse: theme("colors.private.neutral.0");
  --color-icon-brand: theme("colors.private.brand.500");
  --color-icon-info: theme("colors.private.info.500");
  --color-icon-positive: theme("colors.private.positive.600");
  --color-icon-negative: theme("colors.private.negative.500");
  --color-icon-warning: theme("colors.private.warning.500");

  /*
   * Colors: Shadow
   */

  --color-shadow-default: theme("colors.private.neutral.800/.1");

  /*
   * Colors: Interactive - Text
   */

  --color-int-txt-default: theme("colors.private.neutral.800");
  --color-int-txt-default-hover: theme("colors.private.neutral.900");
  --color-int-txt-form: theme("colors.private.neutral.800");
  --color-int-txt-brand: theme("colors.private.brand.500");
  --color-int-txt-brand-hover: theme("colors.private.brand.700");
  --color-int-txt-brand-active: theme("colors.private.brand.800");
  --color-int-txt-negative: theme("colors.private.negative.500");
  --color-int-txt-negative-hover: theme("colors.private.negative.700");
  --color-int-txt-negative-active: theme("colors.private.negative.800");
  --color-int-txt-inverse: theme("colors.private.neutral.0");
  --color-int-txt-inverse-hover: theme("colors.private.neutral.200");
  --color-int-txt-disabled: theme("colors.private.neutral.400");

  /*
   * Colors: Interactive - Border
   */

  --color-int-border-form: theme("colors.private.neutral.400");
  --color-int-border-form-hover: theme("colors.private.neutral.600");
  --color-int-border-disabled: theme("colors.private.neutral.300");
  --color-int-border-brand: theme("colors.private.brand.500");
  --color-int-border-info: theme("colors.private.info.500");
  --color-int-border-positive: theme("colors.private.positive.600");
  --color-int-border-negative: theme("colors.private.negative.500");

  /*
   * Colors: Interactive - Background
   */

  --color-int-bg-primary: theme("colors.private.brand.500");
  --color-int-bg-primary-hover: theme("colors.private.brand.700");
  --color-int-bg-primary-active: theme("colors.private.brand.800");
  --color-int-bg-secondary: theme("colors.private.neutral.0");
  --color-int-bg-secondary-hover: theme("colors.private.brand.50");
  --color-int-bg-secondary-active: theme("colors.private.brand.100");
  --color-int-bg-info: theme("colors.private.info.500");
  --color-int-bg-negative: theme("colors.private.negative.500");
  --color-int-bg-negative-hover: theme("colors.private.negative.700");
  --color-int-bg-negative-active: theme("colors.private.negative.800");
  --color-int-bg-disabled: theme("colors.private.neutral.200");

  /*
   * Colors: Interactive - Icon
   */

  --color-int-icon-brand: theme("colors.private.brand.500");
  --color-int-icon-brand-hover: theme("colors.private.brand.700");
  --color-int-icon-brand-active: theme("colors.private.brand.800");
  --color-int-icon-negative: theme("colors.private.negative.500");
  --color-int-icon-negative-hover: theme("colors.private.negative.700");
  --color-int-icon-negative-active: theme("colors.private.negative.800");
  --color-int-icon-disabled: theme("colors.private.neutral.400");
  --color-int-icon-inverse: theme("colors.private.neutral.0");

  /*
   * Colors: Interactive - Shadow
   */

  --color-int-shadow-default: theme("colors.private.neutral.100");
  --color-int-shadow-brand: theme("colors.private.brand.200");
  --color-int-shadow-info: theme("colors.private.info.100");
  --color-int-shadow-positive: theme("colors.private.positive.100");
  --color-int-shadow-negative: theme("colors.private.negative.100");
}
